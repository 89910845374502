import { Nullable } from "./base";
import { CustomTriggerRunModeValues } from "./customTriggerTracking";
import { ID } from "./id";
import { InterfaceNameValue } from "./issueTypeInterface";
import { IIssueStateWorkflow } from "./issueStateWorkflow";
import { IIssueTypeWorkflow } from "./issueTypeWorflow";
import { INotificationMailSettings } from "./notificationMail";
import { RoleNames, RoleValues, SystemRoleValues } from "./permissions";
import { IResourceManagement } from "./project";

export interface IWorkflow {
  id: ID;
  name: string;
  description: string;
  settings: WorkFlowSettings;
  createdAt: string;
  updatedAt: string;
  lastUpdatedBy: string;
  version?: number;
  createdBy?: string;
  issueStatesWorkflow?: IIssueStateWorkflow[];
  issueTypeWorkflow?: IIssueTypeWorkflow[];
}

export type StateRule =
  | "CanChangeReporter"
  | "CanChangeAssignee"
  | "CanShareAttachments"
  | "CanShareComments"
  | "CanAddAttachmentsAnyway"
  | "CanDeleteAttachmentsAnyway";

export const PerformerUser = {
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  REPORTER: "Reporter",
  ASSIGNEE: "Assignee",
  CREATOR: "Creator"
} as const;

export type PerformerUserKey = keyof typeof PerformerUser;
export type PerformerUserValue = (typeof PerformerUser)[PerformerUserKey];


export type OperativityInterfaceRule = "HiddenForRoles" | "VisibleForRoles";

export const OperativityInterfaceRule = {
  HIDDEN_FOR_ROLES: 'HiddenForRoles',
  VISIBLE_FOR_ROLES: 'VisibleForRoles',
} as const;

export type OperativityInterfaceRuleKey = keyof typeof OperativityInterfaceRule;
export type OperativityInterfaceRuleValue = (typeof OperativityInterfaceRule)[OperativityInterfaceRuleKey];


export type ChangeRules = {
  onState: string[];
  roles: string[];
  stateRules: StateRule[];
};

export type InterfacesFlowEffects = {
  interfaceName: InterfaceNameValue;
  typeInterfaceName: string;
  value: "isValid" | "isEmpty" | "isNotEmpty" | "atLeastOne" | "atLeastOneIsValid";
};

export type InterfaceAutomations = {
  interfaceName: InterfaceNameValue;
  typeInterfaceName: string;
  value: "readonly" | "hidden" | "visible" | "editable" | "not_valid";
};

export type OperativityInterfacesByStateRole = {
  interfaceName: InterfaceNameValue;
  typeInterfaceName: string;
  settings: OperativityInterfaceSettings[];
};

export type TriggerAutomations = {
  name: string,
  runMode: CustomTriggerRunModeValues,
  taskTypeIdsSource?: string[],
  projectId?: string,
  taskTypeId?: string,
  options?: any
}

// Ho riutilizzato il tipo TriggerAutomations cambiando il suo campo options
export type SuspensionTrigger = (Omit<TriggerAutomations, 'options'> & {options: SuspensionTriggerOption});
export type DesuspensionTrigger = (Omit<TriggerAutomations, 'options'> & {options: DesuspensionTriggerOption});

export type Automations = {
  onState: ID;
  assignee?: Nullable<"_creator" | ID>;
  assignee_preference?: Nullable<ID>;
  reporter?: Nullable<"_creator" | ID>;
  reporter_preference?: Nullable<ID>;
  interfaces?: Array<InterfaceAutomations>;
  triggers?: TriggerAutomations[]
};

type ConditionsInterface = Partial<Record<InterfaceNameValue, "isValid" | "isEmpty">>;

export type Flow = {
  from: ID[];
  to: ID[];
  interfaces?: InterfacesFlowEffects[];
};

export type SuspensionFlow = {
  from: ID[];
  to: ID[];
};

export type CreationRules = {
  interfaceName: ID;
  typeInterfaceName: string,
  value: "isValid" | "isEmpty" | "isNotEmpty" | "atLeastOne" | "atLeastOneIsValid";
};
export type CreationStrategies = {
  id: ID; // issueTypeId
  value: "Standard" | "RequestManuale";
};
export type HiddenStatesByRole = {
  states: ID[];
  roles: RoleNames[];
};

export type OperativityInterfaceSettings = {
  states: string[];
  taskTypes: string[];
  rule: OperativityInterfaceRuleValue;
  roles?: RoleNames[];
};

export type WorkflowInterfaceSettings = {
  interfaceName: InterfaceNameValue;
  typeInterfaceName: string;
  settings: any;
};

export interface EntityWithMetadata {
  interfaceName?: string;
  interfaceNameId?: InterfaceNameValue;
  typeInterfaceName?: string;
  entityName: string;
  data: { [key: string]: string };
}

export interface IssueTypeWithEntities {
  taskType: ID;
  taskTypeName: string;
  entitiesMetadata: EntityWithMetadata[];
}

export type TagWorkflowSettings = {
  taskType: ID;
  //infoToCalculate: CalculationInfo;
  placeholder: string[];
};
export type TitleWorkflowSettings = {
  taskType: ID;
  //infoToCalculate: CalculationInfo;
  placeholder: string;
  taskTitleMode: "readonly" | "editable" | "configurable";
};
export interface AppointmentCardOptions {
  taskType: ID;
  showIcon: boolean,
  rows?: CardRowInfo[];
}
export interface CardRowInfo {
  label: string,
  placeholder: string,
  //infoToCalculate?: CalculationInfo
};


/**
 * CalculationInfo contains info to extract issue and interfaces properties
 * if entity is Issue (entityName === 'issue') it will be just {props: string[]}
 * if entity is an interfacie it will be Partial<Record<IssueRelations, {props: string[]}>
 */
// export interface CalculationInfo {
//   //entityProps: { [entityName: string]: { [typeInterfaceName: string]: { props: string[], interfaceName?: IssueRelations } } }
//   entityProps: {
//     [entityName: string]: {IssueRealtions]} | {props: string[]}
//   }
// }

export interface ISuspension {
  enabled: boolean;
  state: ID;
  interfaceName: InterfaceNameValue;
  typeInterfaceName: string;
  flow: SuspensionFlow[];
  notAllowedStates: ID[];
  suspensionTriggers: SuspensionTrigger[],
  desuspensionTriggers: DesuspensionTrigger[],
};

export interface SuspensionTriggerOption {
  projectId: string,
  sourceSuspensionInterface: string,
  destSuspensionInterface: string,
  mapCausa?: {origin: string[], target: string}[],
  copyNotes?: boolean,
};

export interface DesuspensionTriggerOption {
  projectId: string,
};

export interface WorkFlowSettings {
  issueTypes: ID[];
  initialState: ID;
  finalStates: { positive: ID[], negative: ID[] };
  taskTag?: TagWorkflowSettings[];
  taskTitle?: TitleWorkflowSettings[];
  taskDescription?: "readonly" | "editable";
  appointment: AppointmentCardOptions[];
  rules: ChangeRules[];
  automations: Automations[];
  flow: Flow[];
  hiddenInterfacesByRole: OperativityInterfacesByStateRole[];
  hiddenStatesByRole: HiddenStatesByRole[];
  suspension: ISuspension,
  creationStrategies?: CreationStrategies[];
  creationRules: CreationRules[];
  interfaceSettings?: WorkflowInterfaceSettings[];
  resourceManagement?: IResourceManagement;
  uiSettings: UiSettings;
  notificationMailSettings?: INotificationMailSettings[];
  slaManagement: SLAManagement
}

export type SLAManagementDefinitionRule = { taskType: string; interfaceName: InterfaceNameValue; issueTypeInterfaceName: string; field: string; value: string; }
export type SLAMAnagementDefinition = {
  id: string;
  value: number;
  default: boolean;
  rules: SLAManagementDefinitionRule[];
  contract: string;
  customer: string;
};
//export type SLAManagementCustomerDefinition = SLAMAnagementDefinition
//export type SLAManagementContractDefinition = SLAMAnagementDefinition;

export type SLAManagement = {
  workHours: {
    enabled: boolean;
    workDays: number[];
    start: string;
    end: string;
    length: number;
  },
  showOnDueDate: boolean;
  editableByRoles: RoleNames[];
  editableInStates: string[];
  slas: SLAMAnagementDefinition[];
  excludedStates: string[];
  excludedSuspensions: string[];
};

export type UiSettings = Partial<Record<RoleValues, InterfaceSettings>>;

export interface GlobalInterfaceSettings {
  role: SystemRoleValues;
  interface: InterfaceSettings;
}

export interface AddIssueModalSettings {
  assignee: boolean;
  reporter: boolean;
  dueDate: boolean;
  priority: boolean;
  tags: boolean;
  issueType: boolean;
  subject: boolean;
  description: boolean;
  details: boolean;
}

export interface IssueModalSettings {
  assignee: boolean;
  reporter: boolean;
  watcher: boolean;
  dueDate: boolean;
  // datesInfo: boolean;
  priority: boolean;
  tags: boolean;
  external_tasks: boolean;
  info: boolean;
  comments: boolean;
  description: boolean;
  history: boolean;
  details: boolean;
  attachments: boolean;
  olo_req_log: boolean;
  delete: boolean;
  archive: boolean;
  timeline: boolean;
}

export interface IssueTableSettings {
  title: boolean;
  tags: boolean;
  type: boolean;
  protocolNumber: boolean;
  state: boolean;
  milestone: boolean;
  suspension: boolean;
  reporter: boolean;
  assignee: boolean;
  creator: boolean;
  priority: boolean;
  dueDate: boolean;
  updatedAt: boolean;
  createdAt: boolean;
  click: boolean;
}

export interface IssueMenu {
  defaultView: string;
  items: {
    list: boolean;
    board: boolean;
    calendar: boolean;
    map: boolean;
  };
}

export interface MilestoneMenu {
  defaultView: string;
  items: {
    list: boolean;
    board: boolean;
  };
}

export interface MilestoneModalSettings {
  details: boolean;
  description: boolean;
  startDate: boolean;
  dueDate: boolean;
  info: boolean;
}

export interface FilterMenuSettings {
  tag: boolean;
  issueType: boolean;
  issueState: boolean;
  milestone: boolean;
  reporter: boolean;
  assignee: boolean;
  priority: boolean;
}

export interface ProjectMenuSettings {
  visible: boolean;
  features: {
    createButton: boolean;
    issue: boolean;
    settings: boolean;
    archive: boolean;
    trash: boolean;
    external_tasks: boolean;
    export: boolean;
    documentation: boolean;
    milestone: boolean;
    olo_req_log: boolean;
  };
}
export interface InterfaceSettings {
  defaultView: string;
  addIssueModal: AddIssueModalSettings;
  issueModal: IssueModalSettings;
  issueTable: IssueTableSettings;
  milestoneMenu: MilestoneMenu;
  issueMenu: IssueMenu;
  milestoneModal: MilestoneModalSettings;
  filterSettings: FilterMenuSettings;
  projectMenu: ProjectMenuSettings;
  calendar: {
    unavailability: boolean;
  }
}

export const DEFAULT_VIEW_OPTIONS = {
  main: ["issue", "altro"],
  issueMenu: ["list", "board", "calendar", "map"],
  milestoneMenu: ["list", "board"]
};

export const UI_INTERFACE_EVERYTHING_FALSE: InterfaceSettings = {
  defaultView: "issue",
  addIssueModal: {
    assignee: false,
    reporter: false,
    dueDate: false,
    priority: false,
    tags: false,
    issueType: false,
    subject: false,
    description: false,
    details: false,
  },
  issueModal: {
    assignee: false,
    reporter: false,
    watcher: false,
    dueDate: false,
    //datesInfo: false,
    priority: false,
    tags: false,
    external_tasks: false,
    info: false,
    comments: false,
    description: false,
    history: false,
    details: false,
    attachments: false,
    olo_req_log: false,
    delete: false,
    archive: false,
    timeline: false,
  },
  issueTable: {
    title: false,
    tags: false,
    type: false,
    protocolNumber: false,
    state: false,
    milestone: false,
    suspension: false,
    reporter: false,
    assignee: false,
    creator: false,
    priority: false,
    dueDate: false,
    updatedAt: false,
    createdAt: false,
    click: false
  },
  issueMenu: {
    defaultView: "calendar",
    items: {
      list: false,
      board: false,
      calendar: false,
      map: false
    }
  },
  milestoneMenu: {
    defaultView: "list",
    items: {
      list: false,
      board: false
    }
  },
  milestoneModal: {
    details: false,
    description: false,
    startDate: false,
    dueDate: false,
    info: false
  },
  filterSettings: {
    tag: false,
    issueType: false,
    issueState: false,
    milestone: false,
    reporter: false,
    assignee: false,
    priority: false
  },
  projectMenu: {
    visible: false,
    features: {
      createButton: false,
      issue: false,
      settings: false,
      archive: false,
      trash: false,
      external_tasks: false,
      export: false,
      milestone: false,
      olo_req_log: false,
      documentation: false,
    }
  },
  calendar: {
    unavailability: false
  }
} as const;

export const DEFAULT_UI_INTERFACE: InterfaceSettings = {
  defaultView: "issue",
  addIssueModal: {
    assignee: true,
    reporter: true,
    dueDate: true,
    priority: true,
    tags: true,
    issueType: true,
    subject: true,
    description: true,
    details: true,
  },
  issueModal: {
    assignee: true,
    reporter: true,
    watcher: true,
    dueDate: true,
    //datesInfo: true,
    priority: true,
    tags: true,
    external_tasks: true,
    info: true,
    comments: true,
    description: true,
    history: true,
    details: true,
    attachments: true,
    olo_req_log: true,
    delete: true,
    archive: true,
    timeline: true,
  },
  issueTable: {
    title: true,
    tags: true,
    type: true,
    protocolNumber: true,
    state: true,
    milestone: true,
    suspension: true,
    reporter: true,
    assignee: true,
    creator: true,
    priority: true,
    dueDate: true,
    updatedAt: true,
    createdAt: true,
    click: true
  },
  issueMenu: {
    defaultView: "board",
    items: {
      list: true,
      board: true,
      calendar: true,
      map: true
    }
  },
  milestoneMenu: {
    defaultView: "list",
    items: {
      list: true,
      board: true
    }
  },
  milestoneModal: {
    details: true,
    description: true,
    startDate: true,
    dueDate: true,
    info: true
  },
  filterSettings: {
    tag: true,
    issueType: true,
    issueState: true,
    milestone: true,
    reporter: true,
    assignee: true,
    priority: true
  },
  projectMenu: {
    visible: true,
    features: {
      createButton: true,
      issue: true,
      settings: true,
      archive: true,
      trash: true,
      external_tasks: true,
      export: true,
      milestone: true,
      olo_req_log: false,
      documentation: true,
    }
  },
  calendar: {
    unavailability: true
  }
} as const;

export const UI_SETTINGS_SYSADMIN_MIN: InterfaceSettings = {
  ...UI_INTERFACE_EVERYTHING_FALSE,
  defaultView: 'issue',
  issueMenu: {
    defaultView: 'board',
    items: { board: true, calendar: false, list: false, map: false },
  },
  projectMenu: {
    ...UI_INTERFACE_EVERYTHING_FALSE.projectMenu,
    visible: true,
    features: {
      ...UI_INTERFACE_EVERYTHING_FALSE.projectMenu.features,
      settings: true,
      issue: true,
    }
  }
}
