import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { SystemRole, SystemRoleValues } from "@common/interfaces/permissions";
import { IUser, IUserWithAllProjectsRoles, IUserWithRoles } from "@common/interfaces/user";
import { QueryEntity } from "@datorama/akita";
import { AuthQuery } from "@ep-om/project/auth/auth.query";
import { sha1 } from "object-hash";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";
import { UserState, UserStore } from "./user.store";

@Injectable({
  providedIn: 'root'
})
export class UserQuery extends QueryEntity<UserState> {
  //all$ = this.selectAll();

  constructor(protected store: UserStore, private authQuery: AuthQuery) {
    super(store);
  }

  getById$(id: ID) {
    return this.selectEntity(id);
  }

  getById(id: ID) { return this.getEntity(id) }

  myUser$ = this.authQuery.userId$.pipe(
    switchMap(userId => {
      return this.selectEntity(userId)
    }),
    filter(user => !!user))

  myUser() {
    return this.getEntity(this.authQuery.getLoggedUserId());
  }

  amIGlobalSysAdmin$: Observable<boolean> = this.myUser$.pipe(
    map(user => user.globalRoleId === SystemRole.SysAdmin)
  )

  myGlobalRole$: Observable<SystemRoleValues> = this.myUser$.pipe(
    map(user => user.globalRoleId)
  )

  canISeeConfigurator$: Observable<boolean> = this.myUser$.pipe(
    map(user => user.globalRoleId === SystemRole.SysAdmin || user.globalRoleId === SystemRole.SuperUser)
  )

  canISeeImpersonation$: Observable<boolean> = this.myUser$.pipe(
    map(user => user.globalRoleId === SystemRole.SysAdmin || user.globalRoleId === SystemRole.SuperUser)
  )

  // todo : rimuovere
  amIGlobalAdmin$: Observable<boolean> = this.myUser$.pipe(
    map(user => user.globalRoleId === SystemRole.Admin)
  )

  // todo : rimuovere
  amIViewer$: Observable<boolean> = this.myUser$.pipe(
    map(user => user.globalRoleId === SystemRole.Viewer)
  )

  mySettings$ = this.myUser$.pipe(
    filter((user) => { return (!!user && !!(user.settings)) }),
    distinctUntilChanged((x, y) => {
      const before = sha1(x.settings);
      const after = sha1(y.settings);
      return before === after;
    }),
    map(user => user.settings)
  )

  getEntities(id: ID[]) {
    return this.getAll({
      filterBy: entity => id.some(id => entity.id === id)
    })
  }

  getAllEntities(): IUser[] {
    return this.getAll();
  }

  getUserWithRoles(userId: string, projectId: ID): IUserWithRoles {
    const user = this.getEntity(userId);
    const roles = this.authQuery.getValue().projectRole[projectId] || [];
    return { ...user, roles };
  }

  selectUserWithRolesByIdAndProject$(userId: string, projectId: ID): Observable<IUserWithRoles> {
    return this.selectEntity(userId).pipe(
      switchMap(user => {
        return this.authQuery.selectProjectRole$().pipe(
          map(projectRoles => ({ ...user, roles: projectRoles ? projectRoles[projectId] : [] }))
        )
      })
    )
  }

  selectUserWithRolesById$(userId: string): Observable<IUserWithAllProjectsRoles> {
    return this.selectEntity(userId).pipe(
      switchMap(user => {
        return this.authQuery.selectProjectRole$().pipe(
          map(projectRoles => ({
            ...user, roles: Object.keys(projectRoles)
              .reduce((acc, curr) => {
                acc[curr] = projectRoles[curr];
                return acc;
              }, {}) || []
          })
          ),
        );
      }),
    )
  }
}
