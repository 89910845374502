import { Injectable, Injector } from "@angular/core";
import { ID } from "@common/interfaces/id";

import { IIssueContext, InterfaceDataContext } from "@common/utils/workflowDog";
import { IssueQuery } from "./issue/issue.query";

import { IBaseInterfaceData } from "@common/interfaces/base";
import { IIssue } from "@common/interfaces/issue";
import { InterfaceNameValue } from "@common/interfaces/issueTypeInterface";
import { combineLatest, Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { interfaceDataQueryMap, interfaceDataServiceMap } from './interfaceDataMapping';
import { IssueTypeInterfaceQuery } from "./issueTypeInterface/issueTypeInterface.query";
import { MetaInterfaceServiceProvider } from "./metaInterfaces/metaInterfaces.provider.service";
import { AbstractInterfaceDataQuery } from "./interfaceDataModel";
import { EntityStore, EntityState } from "@datorama/akita";
import { CrudService } from "./crudService";
import { IUser } from "@common/interfaces/user";
import { UserQuery } from "./user/user.query";

@Injectable({
  providedIn: 'root'
})
export class IssueContextQuery {
  private interfaceProvider: MetaInterfaceServiceProvider;

  //TODO piccola zozzeria che andrebbe sistemata - anche se non sarà semplicissimo
  constructor(private issueQuery: IssueQuery,
    private injector: Injector,
    private issueTypeInterfaceQuery: IssueTypeInterfaceQuery,
    private userQuery: UserQuery) {
    queueMicrotask(() => this.interfaceProvider = this.injector.get(MetaInterfaceServiceProvider));
  }

  private getQuery(interfaceName: InterfaceNameValue | string & {}): AbstractInterfaceDataQuery<IBaseInterfaceData> {
    return interfaceName.startsWith('_meta_') ? this.interfaceProvider.getService(interfaceName).query : this.injector.get(interfaceDataQueryMap[interfaceName]);
  }
  private getService(interfaceName: InterfaceNameValue | string & {}): CrudService<IBaseInterfaceData, EntityStore<EntityState<IBaseInterfaceData, string>, IBaseInterfaceData, string>, AbstractInterfaceDataQuery<IBaseInterfaceData>> {
    return interfaceName.startsWith('_meta_') ? this.interfaceProvider.getService(interfaceName) : this.injector.get(interfaceDataServiceMap[interfaceName]);
  }

  getIssueContext(issueId: ID): IIssueContext {
    const issue = this.issueQuery.getEntity(issueId);
    let reporter: IUser;
    let assignee: IUser;

    if (issue.assigneeId) {
      assignee = this.userQuery.getEntity(issue.assigneeId);
    }
    if (issue.reporterId) {
      reporter = this.userQuery.getEntity(issue.reporterId);
    }
    const interfaceData: Partial<InterfaceDataContext> = {};
    //const interfaces = Object.keys(interfaceDataQueryMap);
    const interfaces = this.issueTypeInterfaceQuery.getByIssueTypeId(issue.typeId);
    for (const int of interfaces) {
      const query = this.getQuery(int.interfaceName);
      //const query = this.injector.get(interfaceDataQueryMap[interfaceName]);
      const result = query.getByIssueId(issueId);
      interfaceData[int.interfaceName] = result;
    }

    return { issue, reporter, assignee, interfaceData: interfaceData as InterfaceDataContext }
  }

  getIssueContextByTaskType(issueId: ID, typeId: ID): IIssueContext {
    const issue = this.issueQuery.getEntity(issueId);
    let reporter: IUser;
    let assignee: IUser;
    if (issue.assigneeId) {
      assignee = this.userQuery.getEntity(issue.assigneeId);
    }
    if (issue.reporterId) {
      reporter = this.userQuery.getEntity(issue.reporterId);
    }
    const interfaceData: Partial<InterfaceDataContext> = {};
    const issueTypeInterfaces = this.issueTypeInterfaceQuery.getByIssueTypeId(typeId)
    const interfaces = issueTypeInterfaces.map(typeInterfaces => typeInterfaces.interfaceName);
    for (const interfaceName of interfaces) {
      const query = this.getQuery(interfaceName);
      const result = query.getByIssueId(issueId);
      interfaceData[interfaceName] = result;
    }

    return { issue, reporter, assignee, interfaceData: interfaceData as InterfaceDataContext }
  }

  selectIssueContextByTaskType$(issueId: ID, typeId: ID): Observable<IIssueContext> {
    return this.issueQuery.selectEntity(issueId).pipe(
      switchMap(
        issue => combineLatest([
          this.userQuery.selectEntity(issue.assigneeId),
          this.userQuery.selectEntity(issue.reporterId),
          this.issueTypeInterfaceQuery.selectByIssueTypeId$(typeId).pipe(
            map(interfaces => interfaces.map(typeInterfaces => typeInterfaces.interfaceName)),
            switchMap(interfaces => combineLatest(
              interfaces.map(interfaceName => this.getQuery(interfaceName).selectByIssueId$(issueId).pipe(
                map(observable => ({interfaceName, data: observable}))
              ))
            ))
          ),
        ]).pipe(
          map(([assignee, reporter, [...interfacesData]]) => {
            const interfaceData: Partial<InterfaceDataContext> = {};
            for (const {data, interfaceName} of interfacesData) {
              interfaceData[interfaceName] = data;
            }
            return { issue, reporter, assignee, interfaceData: interfaceData as InterfaceDataContext }
          })
        )
      )
    )
  }

  cleanIssueContext(issueId: ID,): void {
    const interfaces = Object.keys(interfaceDataQueryMap);
    for (const interfaceName of interfaces) {
      const service = this.injector.get(interfaceDataServiceMap[interfaceName]);
      service.localRemoveByIssue(issueId);
    }
  }

  selectIssueContext$(issueId: ID): Observable<IIssueContext> {
    return this.issueQuery.selectEntity(issueId).pipe(
      switchMap(
        issue => this.issueTypeInterfaceQuery.selectByIssueTypeId$(issue.typeId).pipe(
          switchMap(typeInterfaces => combineLatest(
            typeInterfaces.map(
              typeInterface => this.getQuery(typeInterface.interfaceName).selectByIssueId$(issue.id).pipe(
                map(interfaceData => ({ typeInterfaceName: typeInterface.interfaceName, interfaceData }))
              )
            )
          )),
          map(interfaceData => {
            let reporter: IUser;
            let assignee: IUser;

            if (issue.assigneeId) {
              assignee = this.userQuery.getEntity(issue.assigneeId);
            }
            if (issue.reporterId) {
              reporter = this.userQuery.getEntity(issue.reporterId);
            }
            return {
              issue,
              reporter,
              assignee,
              interfaceData: interfaceData.reduce((acc: InterfaceDataContext, curr) => {
                acc[curr.typeInterfaceName] = curr.interfaceData
                return acc
              }, {} as InterfaceDataContext)
            }
          }))
      )
    )
  }
}


/**
   * DO NOT USE OUTSIDE THIS FILE
   * @param issue
   * @returns
   */
function isIssue(issue: any): issue is IIssue {
  return (issue.id && issue.stateId);
}
