import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { IssueTypeWorkflowState, IssueTypeWorkflowStore } from "./issueTypeWorkflow.store";
import { Observable } from 'rxjs';
import { IIssueTypeWorkflow } from "@common/interfaces/issueTypeWorflow";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class IssueTypeWorkflowQuery extends QueryEntity<IssueTypeWorkflowState> {
  all$ = this.selectAll();

  constructor(protected store: IssueTypeWorkflowStore) {
    super(store);
  }

  public selectByWorkflow$(id: string): Observable<IIssueTypeWorkflow[]> {
    return this.selectAll({
      filterBy: ({ workflowId }) => workflowId === id
    })
  }

  public selectByWorkflowAndTypeId$(workflowId: string, typeId: string): Observable<IIssueTypeWorkflow> {
    return this.selectAll({
      filterBy: ({ workflowId: wfId, issueTypeId: itId }) => wfId === workflowId && itId === typeId
    }).pipe(
      map(itws => itws[0])
    )
  }

  public selectValidForCreationByWorkflow$(workflowId: string): Observable<IIssueTypeWorkflow[]> {
    return this.selectAll({
      filterBy: [
        issueTypeWorkflow => issueTypeWorkflow.workflowId === workflowId,
        issueTypeWorkflow => issueTypeWorkflow.validForCreation
      ]
    })
  }

  public selectValidForCreation$(): Observable<IIssueTypeWorkflow[]> {
    return this.selectAll({
      filterBy: [        
        issueTypeWorkflow => issueTypeWorkflow.validForCreation
      ]
    })
  }

  public getVisibleInterfacesInCreationByTypeIdAndWorkFlowId(typeId: string, workflowId: string) {
    return this.getAll().find(c => c.issueTypeId === typeId && c.workflowId === workflowId && c.validForCreation)?.visibleInterfaces;
  }

  public selectVisibleInterfacesInCreationByTypeIdAndWorkFlowId$(typeId: string, workflowId: string) {
    return this.selectAll({
      limitTo: 1,
      filterBy: c => c.issueTypeId === typeId && c.workflowId === workflowId && c.validForCreation
    }).pipe(
      map(issueTypeWorkflow => issueTypeWorkflow[0]?.visibleInterfaces)
    )
  }

  public getValidForCreationByWorkflow(workflowId: string): IIssueTypeWorkflow[] {
    return this.getAll().filter(itw => itw.workflowId === workflowId && itw.validForCreation);
  }

  public getByWorkflow(id: string): IIssueTypeWorkflow[] {
    return this.getAll({
      filterBy: ({ workflowId }) => workflowId === id
    });
  }

  public getByIssueType(id: string): IIssueTypeWorkflow[] {
    return this.getAll({
      filterBy: ({ issueTypeId }) => issueTypeId === id
    });
  }

}
