import { IIssue } from '@common/interfaces/issue';
import { addMinutes, differenceInCalendarDays, differenceInMinutes, formatISO, roundToNearestMinutes } from 'date-fns';

export class IssueUtil {
  static isNearDueDate(issue: IIssue) {
    if (!issue.dueDate) return false;
    const today = new Date();
    const dueDate = new Date(issue.dueDate);
    return (today.getTime() - dueDate.getTime()) > -432000000 // 5 giorni
  }
  /* static getIssueTypeIcon(issueTypeId: ID): string {
    return issueTypeId;
  } */

  static getRandomId(): string {
    return `${Math.ceil(Math.random() * 8000)}`;
  }

  static searchString(str: string, searchString: string): boolean {
    str = str ?? '';
    searchString = searchString ?? '';
    return str.trim().toLowerCase().includes(searchString.trim().toLowerCase());
  }

  static calcCurrentAgeing(issue: IIssue): number {
    if (!issue.ageing || !issue.ageing.date) {
      return differenceInCalendarDays(new Date(), new Date(issue.createdAt));
    }
    const differenceDays = differenceInCalendarDays(new Date(), new Date(issue.ageing.date));
    return issue.ageing.partialAgeing + differenceDays;

  }

  static calcDueDate(issue: IIssue) {
    if (!issue.ageing?.sla || issue.ageing.sla.active) {
      return issue.dueDate;
    }
    const sla = issue.ageing.sla;
    const difference = differenceInMinutes(new Date(), new Date(issue.ageing.sla.date || issue.createdAt));
    const dueDate = addMinutes(new Date(issue.createdAt), (issue.ageing.sla.value * 60) + sla.metadata.totalInactive + difference);
    const roundedDueDate = roundToNearestMinutes(dueDate, {nearestTo: 15});
    return formatISO(roundedDueDate, { representation: 'complete' });
  }
}
