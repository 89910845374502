import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'om-error',
  templateUrl: './om-error.component.html',
  styleUrls: ['./om-error.component.scss']
})
export class OmErrorComponent implements OnInit {
  @Input() control: FormControl;
  @Input() detect$?: Observable<boolean>;
  
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.detect$?.subscribe(() => this.cd.detectChanges())
  }
}
